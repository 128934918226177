@import "../../assets/css/Global.scss";

.work-Item-block {
	border-radius: 10px;
	transition: all 0.1s;
	cursor: pointer;
	position: relative;
	width: 350px;
	height: 232px;
	&:hover {
		transform: scale(0.95);
	}
	.work-item-image {
		border-radius: 10px;
		flex: 1;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	.work-item-span-container {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
		&:hover .work-item-span {
			opacity: 1;
		}
	}
	.work-item-span {
		opacity: 0;
		padding: 0 24px;
		flex: 1 1;
		display: block;
		position: absolute;
		max-width: 100%;
		height: 100%;
		border-radius: 10px;
		background: rgba(#000, 0.8);
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		transition: all 0.2s;
		button {
			padding: 1rem 0;
			width: 100%;
			border-radius: 10px;
			border: none;
			background: $primary-color;
			color: $color-light;
			cursor: pointer;
			font-size: 1rem;
			font-weight: $medium;
			&:hover {
				background: $primary-color-dark;
			}
		}
		p {
			line-height: 1.5rem;
			max-height: 5rem;
			font-size: 1rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			color: $color-light;
			text-align: center;
		}
	}
}

@media (max-width: 768px) {
	.work-Item-block {
		height: initial;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
