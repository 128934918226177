@import "../../assets/css/Global.scss";

.footer-block{
    width: 100%;
    background: $gradient;
    margin-top:256px;
    position: relative;
    .footer-item-title{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2{
            color:$secondary-color-title;
            font-size: 2rem;
            text-transform: uppercase;
            span{
                color: $primary-color;
            }
        }
        p{
            color:$secondary-color-text;
            max-width: 30rem;
            margin-top:16px;
            text-align: center;
            font-size: 1.15rem;
            padding: 0 16px;
        }
    }
    .footer-item-social-media{
        display: flex;
        align-items: center;
        justify-content: center;
        margin:64px 0 0 0;
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            gap:16px;
            li{
                list-style: none;
                a svg {
                    transition: all .05s;
                    // opacity: .8;
                    &:hover{
                        filter: brightness(1.5);
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    .footer-block-info{
        width: 100%;
        margin-top:64px;
        background: $color-light;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;

      
            font-size: .80rem;
            color: rgba($secondary-color, .65);
            
            strong{
                color:$secondary-color;
                font-weight: $medium;
            }
            .footer-block-info-dot{
                margin: 0 6px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $primary-color;
            }
    }
}
@media(max-width:768px){
    .footer-block-info{
        margin-top: 0;
    }
}