@import "../../assets/css/Global.scss";

.home-box {
  width: 100%;
  min-height: 100vh;
  .home-box-hero {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-box-image {
    width: 100%;
    height: 100vh;
    background: url("../../assets/img/background-hero.jpg") center center
      no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    padding: 8px;
  }
  .home-box-title {
    color: $secondary-color-title;
    font-weight: $light !important;
    font-size: 3.5rem;
  }
  .home-box-image-arrow-down {
    position: absolute;
    bottom: 25px;
    animation: animaArrowDown 0.55 + s ease-in infinite;
  }
}
@keyframes animaArrowDown {
  0%,
  100% {
    transform: translateY(-0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media(max-width:768px){
  .home-box {

  .home-box-title{
    font-size: 2.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
}