@import "./color.scss";
@import "./fonts.scss";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $font;
}

html {
  scroll-behavior: smooth;
}
.container {
  position: relative;
  width: 100%;

  .content {
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
  }
}
a {
  text-decoration: none;
  transition: all 0.1s;
  font-size: 0.85rem;
}
h1,
h2,
h3,
p {
  color: $primary-color-title;
  margin: 0;
  padding: 0;
}
strong {
  font-weight: $bold;
  color: $primary-color;
}
.title {
  font-size: 2.5rem;
  font-weight: $bold;
}
.text {
  font-size: 1.15rem;
  color: $primary-color-text;
}

@media (max-width: 768px) {
  html {
    font-size: 93%;
  }
}
@media (max-width: 512px) {
  html {
    font-size: 87%;
  }
}