@import "../../assets/css/Global.scss";
#header-section-blue{
  background: $gradient;
  > .header-section-content {
  .header-section-title {
    color: $secondary-color-title;
  }
  .header-section-text {
    color: $secondary-color-text;
    font-weight: $light;
  }
}
}

#header-section-light{
  min-height: initial;
  background: transparent;
  padding: 0 0 64px;
  > .header-section-content {
  .header-section-title {
    color: $primary-color-title;
  }
  .header-section-text {
    color: $primary-color-text;
    font-weight: $light;
  }
}
}

.header-section-block {
  width: 100%;
  min-height: 500px;
  padding: 128px 16px 32px;
  align-items: flex-start;
  z-index: -1;
  > .header-section-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .header-section-title,
    .header-section-text {
      max-width: 500px;
      text-align: center;
    }
  }
}
@media (max-width: 1080px){
  .header-section-block{
    padding: 84px 8px;
    min-height: 100px;
    align-items: center;
    justify-content: center;
  .header-section-content {
    flex-direction: column;
    .header-section-title{
      font-size: 1.85rem;
    }
    .header-section-text {
      font-size: 1.25rem;
      margin-top:8px
    }
  }
}
}