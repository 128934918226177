@import "../../assets/css/Global.scss";

.works-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 16px;
    .works-block-content{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:16px;
        flex-wrap: wrap;
        padding: 0 32px;
    }
}