@import "../../assets/css/Global.scss";

@keyframes showModalContainer{
  to{
  opacity: 1;

  }
}
@keyframes showModalContent{
to{
  transform: scale(1);
  opacity: 1;
}
}
.modal-block {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  min-height: 600px;
  justify-content: center;
  opacity: 0;
  animation: showModalContainer .2s forwards;
  .modal-block-container{
    max-width: 650px;
    .modal-block-content{
      animation: showModalContent .2s ease forwards;
      transform: scale(.3);
      opacity: 0;
      width: 100%;
      background:hsl(197, 54%, 99%);
      border-radius: 5px;
      padding: 32px 44px;
      position: relative;
      display: flex;
      flex-direction: column;
      gap:8px;
      align-items: flex-start;
      grid-template-areas: 'text''image''links';
      box-shadow: 0 0 30px #000;
      .modal-block-item-img {
        text-align: center;
        display: flex;
        gap:32px;
        flex-direction: column-reverse;
        width: 100%;
      }
      .modal-block-item-img img{
        width: 100%;
        height: 200px;
        margin:16px auto;
        object-fit: cover;
      }
      .modal-block-item-left-text,.modal-block-item-links,.modal-block-item-tags{
        width: 100%;
        max-width: initial;
      }
    }

    .icon-closeModal{
      position: absolute;
      right: 4px;
      top:4px;
      padding: 0;
      cursor:pointer;
      border:none;
      border-radius: 5px;
      background: transparent;
      img {
        width:30px;
      }
    }
    .modal-item{
      height: 100%;
      flex:1;
    }
    .modal-block-item-left{
      display: flex;
      width: 100%;
      max-width: 500px;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      width: 100%;
      gap:16px;
    }
    .modal-block-item-left-text{
      grid-area: text;
      p{
        color:$primary-color-text;
        font-size: 1rem;
        max-width: 500px;
      }
      h2{
        color:$primary-color-title;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 8px;
        margin-left:-8px
      }
    }
   .modal-block-item-links{
     display: inline-flex;       
     width: 100%;
     max-width: 500px;
     gap:16px;
     grid-area: links;
     margin-top: 24px;
     a{
       flex:1;
     display: flex;       
      align-items: center;
      justify-content: center;
       width: 100%;
       padding:1rem 2rem;
       background: $primary-color;
       border-radius: 5px;
       text-align: center;
       color:$color-light;
       font-size: 1rem;
       font-weight: $medium;
       letter-spacing: .035rem;
       white-space: nowrap;
       &:hover.modal-block-item-link--active{
        background: $primary-color-dark;
       }
       &.url-project{
         font-weight: $bold;
         padding: 8px 0;
         background: none;
         border:none;
         color:$secondary-color;
         &:hover{
          background: none;
          color:none;
          opacity: .8;
         }
       }
     }
     .modal-block-item-link--disabled{
      font-weight: $bold;
       background: #e7c496;
       color:#614726;
       cursor: not-allowed;
       display: flex;
       align-items: center;
       justify-content: center;
       gap:8px;
     }
   }
    .modal-block-item-right{
      display: flex;
      flex-direction: column;
      gap:32px;
    }
    .modal-block-item-img{
      grid-area: image;
      img{
        width: 400px;
        border-radius: 10px;
      }
    }
    .modal-block-item-tags{
      grid-area: tags;
      display: flex;
      justify-content: flex-start;
      margin-top:0px;
      .content-tags{
      display: flex;
      align-items: center;
      gap:5px;
      flex-wrap: wrap;

      >*{
        text-align: center;
        flex:1;
        max-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color:$secondary-color;
        border:2px solid;
        border-color: $secondary-color;
        font-size: .75rem;
        border-radius: 10px;
      }
    }
    }
  }
}

@media(max-width:768px){
  .modal-block {
    min-height: 100vh;
    align-items: flex-start;
    .modal-block-container{
      .modal-block-content{
        border-radius:0;
        padding: 54px 24px;
        .icon-closeModal img{
          width: 32px;
        }
        .modal-block-item-tags .content-tags > *{
          font-size: .88rem;
          padding: 8px 18px;
        }
        .modal-block-item-links{
          flex-direction: column;
        }
      }
    }
  }
}

@media(max-width:512px){
  .modal-block {
    .modal-block-container{
      .modal-block-content{
        .modal-block-item-tags .content-tags > *{
          font-size: 10px;
          padding: 8px 1rem;
        }
      }
    }
  }
}