@import "../../assets/css/Global.scss";

.contactSection-block{
    max-width: 1200px;
    width: 100%;
    min-height: 200px;
    margin:0 auto;
    background: $secondary-color;
    border-radius: 10px;
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
    transform:translateY(-50%);
    box-shadow: 0px 4px 15px 0px rgba(0,0,0,.4);
    .contactSection-content{
        display: flex;
        flex:1;
        align-items: center;
        justify-content: center;
        gap:6rem;
        .contactSection-item-left{
            >h1{
                font-size: 2.25rem;
                font-weight: $medium;
                text-transform: uppercase;
                color:$secondary-color-title
            }
            >p{
                font-size: 1rem;
                color:$color-text-footer
            }
        }
        .contactSection-item-right{
            a{
                padding: 1.25rem 4rem;
                font-size: 1rem;
                text-transform: uppercase;
                border-radius: 10px;
                border:2px solid $primary-color;
                background: none;
                color:$color-light;
                font-weight: $medium;
                cursor: pointer;
                transition: all .2s;
                &:hover{
                    transform: scale(1.025);
                    background: rgba($primary-color, .1);
                    
                }
            }
        }
    }
}
@media (max-width: 1080px) {
    .contactSection-block{
        padding: 64px 16px;
        border-radius: 0;
        .contactSection-content{
            flex-direction: column;
            gap:4rem;
            .contactSection-item-left{
                >h1{
                    text-align: center;
                    font-size: 1.75rem;
                }
                >p{
                    text-align: center;
                    font-size: 1.15rem;
                    margin-top:8px
                }
            }
        }
    }
  }