@import "../../assets/css/Global.scss";

.skills-block {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;

  > .skills-block-content {
    position: relative;
    top: -180px;
    width: 90%;
    min-height: 500px;
    background: $color-light;
    border-radius: 5px;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 64px 32px;
    border: solid 1px $border-color;
  }
}

@media(max-width:1080px){
  .skills-block{
    .skills-block-content{
      flex-direction: column;
    top: 0px;
      gap:32px;
    border: 0;
      > *{
        width: 100%;
      }
    }
  }
}