@import "../../assets/css/Global.scss";

.tag-item {
  padding: 7px 16px;
  border-radius: 5px;
  border: 3px solid $primary-color;
  color: $primary-color;
  font-weight: $bold;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  white-space: nowrap;
}
