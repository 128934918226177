@import "../../assets/css/Global.scss";

.presentation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 88px 32px;
  .presentation-content {
    max-width: 750px;
    display: inline;
    .presentation-block {
      .presentation-block-title {
        margin-left: -1.5rem;
      }
    }
    .presentation-block-links {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 32px;
      margin-top: 32px;
      flex-wrap: wrap;
      max-width: 600px;
      .presentation-block-links__link {
        flex:1;
        text-align: center;
        padding: 12px 44px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: $medium;
      }
      .presentation-block-links__link--contrast {
        background: $primary-color;
        color: $color-light;
        letter-spacing: 0.05rem;

        &:hover {
          background: $primary-color-dark;
        }
      }
      .presentation-block-links__link--light {
        padding: 9px 44px;
        border: solid 3px $secondary-color;
        color: $secondary-color;
        letter-spacing: 0.02rem;
        &:hover {
          background: $secondary-color;
          color: $color-light;
        }
      }
    }
  }
}

@media(max-width:768px){
  .presentation {
    padding: 64px 44px;
    .content {
    .presentation-block {
      padding: 0 8px;
      .presentation-block-title {
        margin-left: -1rem;
        font-size: 2.25rem;
      }
    }
  }
  }
}