$primary-color: #1794c1;
$secondary-color: #1a242e;
$primary-color-dark: #223c46;

//Background principal
$gradient: linear-gradient(106.15deg, #1794c1 12.76%, #09243d 98.04%);
$color-text-footer: #babdbf;

//Textos, titulos e links
$primary-color-title: #111c25;
$secondary-color-title: #f3f3f3;

$primary-color-text: #3b3b3b;
$secondary-color-text: #c6d6e4;

$color-light: #fff;
$color-dark: #08121b;

// Botões e cards
// $background-card: #f4f4f4;
// $border-color-card: #c7c7c7;
// $border-color-tag: #a9a9a9;

$border-color: #e0e1e4;
// $button-color-text: #fff;
// $button-color: #51629f;
// $button-color-dark: #374476;
// $color-contrast: #4a5768;
// $background-color-skill-item: #5593a6;
// $background-color-status: #d3d2c5;
