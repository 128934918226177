@import "../../assets/css/Global.scss";
.skills-item-block {
  width: 33%;
  height: 100%;
  padding: 0 32px;
  position: relative;
  .skills-item-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    .skills-item-header-block {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      .skills-item-title {
        text-transform: uppercase;
      }
    }
    .skills-item-text-block {
      .skills-item-text {
        text-align: center;
        color: $primary-color-text;
        font-size: 0.95rem;
        max-width: 400px;
      }
    }
    .skills-tags-block {
      max-width: 400px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 8px;
      padding: 44px 0 0 0;
    }
  }
  &:nth-child(even)::before {
    content: "";
    height: calc(100% + 128px);
    width: calc(100% + 32px);
    top: -36px;
    left: -16px;
    position: absolute;
    border: 1px solid $border-color;
    border-top: 0;
    border-bottom: 0;
  }
}
@media(max-width:1080px){
  .skills-item-block {
    padding:32px 0;

    .skills-item-content {
      display: flex;
    }
    &:nth-child(even){
    padding: 64px 0px;

    }
    &:nth-child(even)::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid $border-color;
      border-right: 0;
      border-left: 0;
    }
    .skills-tags-block {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 8px;
      padding: 44px 0 0 0;
      >*{
        flex:1;
        text-align: center;
      }
    }
  }
}