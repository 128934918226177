@import "../../assets/css/Global.scss";


.header-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 64px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  .logo {
    width: 50px;
  }
  .github-icon-header {
    > svg {
      transition: all 0.1s;
      path {
        transition: all 0.1s;
      }
    }
    &:hover svg {
      transition: all 0.1s;
      transform: scale(1.1);
      &:hover path {
        fill: $primary-color;
        color: $primary-color;
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      > a {
        color: $color-light;
        opacity: 0.8;
        padding: 5px 18px;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: $light;
        letter-spacing: 0.08rem;
        &:hover {
          opacity: 1;
        }
      }
      // .btn-falecomigo {
      //   color: $primary-color;
      //   border: 2px solid $primary-color;
      //   border-radius: 25px;
      //   font-family: 0.65rem;
      //   font-weight: $normal;
      //   &:hover {
      //     background-color: $primary-color;
      //     color: #fff;
      //     text-decoration: none;
      //   }
      // }
    }
  }
}
.navShadow {
  animation: animationNav 0.2s forwards;
  animation-delay: 0.3s;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.9);
  transform: translateY(-100%);
  position: fixed;
  top:0
}
@keyframes animationNav {
  to {
    transform: translateY(0%);
  }
}
@keyframes animationNavMoblie {
  to {
    transform: translateY(-50px);
  }
}
@media (max-width: 764px) {
  .header-block {
    padding: 16px 32px;
    .containerLinks {
      a {
        font-size: 0.9rem;
      }
    }
  }
}
@media (max-width: 512px) {

  .header-block {
    padding: 0;
    background: rgba(0, 0, 0, 0.7);
    .content {
      padding-top: 16px;
      justify-content: space-between;
      display: grid;
      grid-template-rows: 25px 60px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "logo redesociais" "nav nav";
      .logo {
        width: 45px;
        grid-area: logo;
        margin-left: 24px;
      }
      .logo-github {
        margin-right: 24px;
        display: flex;
        justify-content: flex-end;
        grid-area: redesociais;
        float: right;
        > img {
          width: 25px;
        }
      }
      .nav-main {
        grid-area: nav;
        margin-top: 6px;
        gap: 18px;
        justify-content: space-between;
        padding: 0px 16px;
        height: 85%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        // height: 60px;
        a {
          font-size: 0.8rem;
          padding: 5px 8px;
        }
      }
    }
  }
  .navShadow {
    animation: animationNavMoblie 0.2s forwards;
    background: rgba(0, 0, 0, 0.9);
  }
}
